import { Card, Collapse, Form, Modal, Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import ReactJson from 'react-json-view';
import { formatCurrency, statusForDetails } from '../../utils/helper';

export default function ModalTransaction(props) {
  const { title, show, close, confirmLoading, info, edit, admin } = props;
  const [form] = Form.useForm();
  const columnsTable = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'code',
      key: 'code',
      align: 'center'
    },
    {
      title: 'Tên gói',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => <span>{record.mobileData.name}</span>,
      align: 'center'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: tag => {
        let st = statusForDetails(tag);
        let color = tag === 0 ? '#d9a61c' : tag === 1 ? '#2029a8' : tag === 2 ? 'green' : tag === 3 ? 'volcano' : tag === 4 ?'#730d34' : '#e8a81e';
        return (
          <Tag color={color} key={st}>
            {st.toUpperCase()}
          </Tag>
        );
      },
      align: 'center'
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>,
      align: 'center'
    },
    {
      title: 'Tổng số tiền',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (value) => <span>{formatCurrency(value)}</span>,
      align: 'center'
    },
    {
      title: 'Địa chỉ IP',
      dataIndex: 'ip',
      key: 'ip',
      align: 'center'
    },
    // {
    //   title: 'Phương thức',
    //   dataIndex: 'logs',
    //   key: 'paymentMethod',
    //   align: 'center',
    //   render: (text) => text.map((item) => item?.paymentMethod)
    // },
    {
      title: 'Mô tả giao dịch',
      dataIndex: 'description',
      key: 'description',
      align: 'center'
    }
  ];
  React.useEffect(() => {
    form.setFieldsValue(info);
  }, [info, show, edit]);

  const items = [
    {
      key: '1',
      label: 'Raw Data',
      children: <ReactJson src={info} style={{ wordBreak: 'break-word' }} />
    }
  ]

  return (
    <Modal
      title={title}
      open={show}
      footer={null}
      onCancel={close}
      className='p-3'
      centered
      width={'90%'}
      confirmLoading={confirmLoading}
    >
      <div className='flex flex-col justify-center items-center gap-5'>
        <Card bordered={true} className='w-full overflow-scroll scrollable-content' headStyle={{ display: 'none' }}>
          <Table
            columns={admin === true ? columnsTable : columnsTable?.filter(item => item?.title !== 'Địa chỉ IP')}
            rowKey={(record) => record.id}
            dataSource={[info]}
            pagination={false}
          />
        </Card>
        {admin === true && (<Collapse items={items} className='font-bold w-full overflow-hidden bg-white' expandIconPosition='end'/>)}
      </div>
    </Modal >
  );
}
